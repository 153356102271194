//--> colors

$green-1: #35e65d;
$grey-0: #808080;
$grey-1: #cdd8ec;
$grey-2: #f4f7f9;
$grey-3: #b5b5b5;
$turqois-1: #35cce6;
$turqois-2: #a3eaf7;
$white: #fff;
$red: #ff0000;


$black:    											#000000 !default;
$white:    											#ffffff !default;
$primary: #663399;
$gray-100: 											#F3F6F9 !default;
$gray-200: 											#EBEDF3 !default;
$gray-300: 											#E4E6EF !default;
$gray-400: 											#D1D3E0 !default;
$gray-500: 											#B5B5C3 !default;
$gray-600: 											#7E8299 !default;
$gray-700: 											#5E6278 !default;
$gray-800: 											#3F4254 !default;
$gray-900: 											#181C32 !default;
$dark:          									$gray-900 !default;
$dark-75:          									$gray-800 !default; 
$dark-65:          									$gray-700 !default; 
$dark-50:          									$gray-600 !default; 
$dark-25:          									$gray-400 !default; 
$text-muted:                  						$gray-500 !default; 
$danger: #F64E60;

// Full screen mixins

@mixin widget-container-fs {
  height: 100vh;
  margin: 0;
  max-width: none;
  width: 100%;
}

@mixin header-fs {
  border-radius: 0;
  flex-shrink: 0;
  position: relative;
}

@mixin title-fs {
  padding: 10px 0 10px 0;
}

@mixin close-button-fs {
  border: 0;
  display: block;
  position: absolute;
  right: 10px;
  top: 20px;
  width: 40px;
}

@mixin close-fs {
  width: 20px;
  height: 20px;
}

@mixin messages-container-fs {
  height: 100%;
  max-height: none;
}

@mixin conversation-container-fs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@mixin launcher-fs {
  bottom: 0;
  margin: 20px;
  position: fixed;
  right: 0;
}

@mixin sender-fs {
  border-radius: 0;
  flex-shrink: 0;
}

//--> animations

@mixin animation ($delay, $duration, $animation) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-timing-function: linear;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: forwards; 
  -moz-animation-timing-function: linear;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards; 
  animation-timing-function: linear;
}

@mixin keyframes ($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(rotation-lr) {
  from  {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0);
  }
}

@include keyframes(rotation-rl) {
  from  {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0);
  }
}

@include keyframes(slide-in) {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(slide-out) {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

@include keyframes(slide-right) {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(slide-left) {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(zoom-in) {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@include keyframes(rotation) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}





//--> main container

.rcw-widget-container {
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 0;
  max-width: 360px;
  position: fixed;
  right: 0;
  width: 90vw;
  z-index: 9999;
}
.rcw-full-screen {
  @include widget-container-fs;
}

.rcw-previewer .rcw-message-img {
  cursor: pointer;
}

//--> launcher

.rcw-launcher {
  @include animation(0, 0.3s, slide-in);
  align-self: flex-end;
  border: 0;
  border-radius: 50%;
  box-shadow: 0px 2px 10px 1px $grey-3;
  height: 52px;
  margin-top: 13px;
  cursor: pointer;
  width: 52px;

  &:focus {
    outline: none;
  }
}

.rcw-launcher-avatar {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  opacity: 1;
}

.rcw-open-launcher {
  @include animation(0, 0.3s, rotation-rl);
  height: 32px;
  width: 33px;
}

.rcw-close-launcher {
  width: 20px;
  @include animation(0, 0.3s, rotation-lr);
}

.rcw-hide-sm {
   display: none;
}

/*
@media screen and (max-width: 800px){
  .rcw-launcher {
    @include launcher-fs;
  }
.rcw-hide-sm {
   display: none;
}

  
}
*/

//--> badge

.rcw-launcher {

  .rcw-badge{
    position: fixed;
    top: -10px;
    right: -5px;
    background-color: $red;
    color: $white;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
  }
}

//--> conversations container

.rcw-conversation-container {
  border-radius: 10px;
  box-shadow: 0px 2px 10px 1px $grey-3;

  &.active {
    @include animation(0, 0.3s, slide-in);
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 0.3s ease, transform 0.3s ease;

  }

  &.hidden {
     @include animation(0, 0.5s, slide-out);
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
}

.rcw-full-screen {
  .rcw-conversation-container {
    @include conversation-container-fs;
  }
}

//--> header

.rcw-conversation-container {

  .rcw-header {
    border-radius: 10px 10px 0 0;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px 0 10px;
  }

  .rcw-title {
    font-family:  Poppins, Helvetica, sans-serif !important;    
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    padding: 10px 0 10px 0;
   
  }

  .rcw-close-button {
    display: none;
  }

  .avatar {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: 4px solid #e4e9f0;
    vertical-align: middle;
    align-self: center;
    color: #FFFFFF;
    margin-top: -40px;  
    background-color: #FFFFFF;     
  }

  .rcw-header-icon {
    width: 45px;
    height: 45px;
    border-radius: 100%;    
    margin-right: 7px;
    vertical-align: middle;
    color: #FFFFFF;    
  }


}

.display-none {
  display: none;
}

.rcw-full-screen {

  .rcw-header {
    @include header-fs;
  }

  .rcw-title {
    @include title-fs;
  }

  .rcw-close-button {
    @include close-button-fs;
  }

  .rcw-close {
    @include close-fs;
  }

  .rcw-launcher {
    @include launcher-fs;
  }

  .rcw-sender {
    @include sender-fs;
  }
}

//--> messages

.rcw-messages-container {
  background-color: #f5f5f7;
  height: 500px;
  max-height: 500px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 999999999;
}

.rcw-full-screen {

  .rcw-messages-container {
    @include messages-container-fs;
  }
}

.rcw-messages-checkmark {
    position: absolute;
    height: 16px;
    width: 16px;
    right: -20px;
    bottom: 15px;
    background-color: transparent;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    z-index: 999;
}

.rcw-messages-flag {
    position: absolute;
    height: 16px;
    width: 16px;
    right: 4px;
    top: 5px;
    background-color: transparent;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    z-index: 999;
}

.rcw-messages-flag-check {
    position: absolute;
    height: 16px;
    width: 16px;
    right: -20px;
    top: 3px;
    background-color: transparent;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    z-index: 999;
}

@keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
}


.rcw-messages-blurb {
    @include animation(0, 0.3s, zoom-in);
    border-radius: 24px 24px 24px 3px;
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: #e4e9f0;
     
    font-family:  Poppins, Helvetica, sans-serif !important;    
    font-weight: 400;

    color: $dark-65;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 50px;
    text-align: left;
    padding: 15px 26px 16px 21px;
    align-self: flex-start;
    position: relative;
    opacity: 1;
    white-space: pre-wrap;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
}

.rcw-messages-form-content {
    @include animation(1, 0.6s, slide-right);
    opacity: 1;
    flex-direction: column;
    align-self: flex-end;
    background-color: #fff;
    border-radius: 24px 24px 3px 24px;
    margin: 0 16px 16px 40px;
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    z-index: 2;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    position: relative;
    display: -webkit-box;
    display: flex;
    padding-bottom: 20px;
}
.rcw-messsages-form-content-exit {
  @include animation(1, 0.3, slide-out); 
}

.rcw-messages-submitted-content {
  @include animation(1, 0.5s, zoom-in);
    opacity: 1;
    flex-direction: column;
    align-self: flex-end;
    background-color: $white;
    border-radius: 24px 24px 3px 24px;
    margin: 0 16px 16px 40px;
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    z-index: 2;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    position: relative;
    display: -webkit-box;
    display: flex;
    padding-bottom: 20px;
}

.rcw-messages-submitted-text {
  font-family:  Poppins, Helvetica, sans-serif !important;  
  color: $dark-65;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; 
}

.rcw-messages-submitted-phone {
  color: $dark-75;
  font-weight: 800;
}

.rcw-messages-submitted-name {
  color: $dark-65;
  font-weight: 400;
}

.rcw-messages-submitted-msg {
  color: $dark-65;
  font-weight: 400;
}

.rcw-messages-submitted-received {
  @include animation(0, 0.3s, slide-left);
  display: flex;  
  justify-content: flex-end;
  margin: -10px 25px 0px 0px;
  font-family:  Poppins, Helvetica, sans-serif !important;    
  font-size: 12px;
  font-weight: 400;
  color: $dark-50;

}

.rcw-messages-conf {
  @include animation(0, 0.3s, slide-right);
  border-radius: 24px 24px 24px 3px;
  background-color: #ffffff;

  font-family:  Poppins, Helvetica, sans-serif !important;    
  font-weight: 400;

  color: $dark-65;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 60px;
  padding: 16px 40px 16px 40px;
  align-self: flex-start;
  position: relative;
  opacity: 1;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center; 
}
.rcw-messages-conf-header {
  display: flex;
  align-self: center;
  font-size: 14px;
  font-weight: 800;
  color: $dark-75;
  padding-bottom: 8px;
}
.rcw-messages-conf-content {
  display: flex;
  align-self: center;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  color: $gray-500;
  padding-bottom: 8px;
  line-height: 15px;
}
.rcw-messages-conf-phone {
  display: flex;
  align-self: center;
  font-size: 14px;
  font-weight: 800;
  color: $dark-75;
  padding-bottom: 8px;
}
.rcw-messages-conf-checkmark {
  display: flex;
  align-self: center;
  margin-bottom: -5px;
}


/* form starting stylings ------------------------------- */
.rcw-messages-group        { 
  position:relative; 
  margin-top: 10px;
  margin-bottom:20px; 
  margin-right: 5px;
  margin-left: 5px;
}
.rcw-messages-input, .rcw-messages-textarea         {
  font-family:  Poppins, Helvetica, sans-serif !important;  
  color: $dark-65;
  font-size:15px;
  padding:10px 0px 5px 0px;
  display:block;
  width:100%;
  border:none;
  border-bottom:1px solid #dedede;
  resize: none;
  margin-top: 5px;
  line-height: 20px;
  overflow: hidden;
}

.rcw-form-error {
  font-family:  Poppins, Helvetica, sans-serif !important;  
  color: $danger;
  font-size:12px;
  padding:3px 0px 3px 0px;
  display:block;
}


.rcw-messages-input:focus, .rcw-messages-textarea:focus     { outline:none; }

/* LABEL ======================================= */
.rcw-messages-label          {
  color: $text-muted; 
  font-family:  Poppins, Helvetica, sans-serif !important;    
  font-weight: 400;
  font-size: 13px;
  position:absolute;
  pointer-events:none;
  left:0px;
  top:10px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

/* active state */
.rcw-messages-input:focus ~ label, 
.rcw-messages-input:valid ~ label, 
.rcw-messages-textarea:focus ~ label, 
.rcw-messages-textarea:valid ~ label    
{
  top:-10px;
  font-size:11px;
  font-weight: 500;
  //color:#663399;
}

/* BOTTOM BARS ================================= */
.rcw-messages-bar  { 
  position:relative; 
  display:block; 
  width: 100%;
}

.rcw-messages-bar:before, .rcw-messages-bar:after   {
  content:'';
  height:1px; 
  width:0;
  bottom:1px; 
  position:absolute;
  background: #663399;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

/* active state */
.rcw-messages-input:focus ~ .rcw-messages-bar:before, 
.rcw-messages-input:focus ~ .rcw-messages-bar:after, 
.rcw-messages-textarea:focus ~ .rcw-messages-bar:before,  
.rcw-messages-textarea:focus ~ .rcw-messages-bar:after  {
  width:50%;
}

.rcw-messages-bar:before {
  left:50%;
}
.rcw-messages-bar:after {
  right:50%; 
}



.rcw-bar-before {  
  content:'';
  height:1px; 
  width:0;
  position:absolute;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

.rcw-bar-after {
  content:'';
  height:1px; 
  width:0;
  position:absolute;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

/* active state */
.rcw-messages-input:focus ~ .rcw-bar-before, 
.rcw-messages-input:focus ~ .rcw-bar-after, 
.rcw-messages-textarea:focus ~ .rcw-bar-before,  
.rcw-messages-textarea:focus ~ .rcw-bar-after  {
  width:50%;
}

.rcw-bar-before {
  left:50%;
}
.rcw-bar-after {
  right:50%; 
}


/* HIGHLIGHTER ================================== */
.rcw-messages-highlight {
  position:absolute;
  height:60%; 
  width:100px; 
  top:25%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}

/* active state */
.rcw-messages-input:focus ~ .rcw-messages-highlight, .rcw-messages-textarea:focus ~ .rcw-messages-highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from { background: $gray-500; }
  to  { width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
  from { background: $gray-500; }
  to  { width:0; background:transparent; }
}
@keyframes inputHighlighter {
  from { background: $gray-500; }
  to  { width:0; background:transparent; }
}

//5264AE - orig highligh color

.rcw-messages-submission-leave {
  @include animation(0, 0.5s, slide-out);
}
.rcw-messages-submission p{

  font-family:  Poppins, Helvetica, sans-serif !important;    
  font-weight: 300;
  font-size: 11px;

  color: $dark-25;

  line-height: 15px;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right:16px;
  text-align: center;
  padding: 0px 26px 0px 21px;
  align-self: flex-middle;
  position: relative;
  opacity: 1;
  white-space: pre-wrap;
  word-wrap: break-word;
  -webkit-animation: innerBubbleGrow .2s ease 0s forwards;
  animation: innerBubbleGrow .2s ease 0s forwards;
}

.rcw-messages-button-container {
  @include animation(0, 0.5s, slide-in);
  display: flex;
  align-items: center;
  justify-content: center; 
  margin-bottom: 16px; 
}

.rcw-messages-submit-button {  
  color: $white;
  font-family:  Poppins, Helvetica, sans-serif !important;    
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  border-radius: 8px;
  border: 0;
  width: 100px;
  height: auto;
  outline: none;  
  cursor: pointer;  
}

.rcw-submit-button-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rcw-submit-button-icon {
  margin: 0;
  margin-left: 7px;
  padding: 0;  
  height: 14px;
  width: 14px;
}

.rcw-rotate-submit {  
  @include animation(0, 1s, rotation);
}


.rcw-button-disabled {  
  opacity: 0.6;
  color: $white;
  font-family:  Poppins, Helvetica, sans-serif !important;    
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  border-radius: 8px;
  border: 0;
  width: 100px;
  height: auto;
  outline: none;  
  cursor: default;
}




.rcw-messages-cancel-button {
  background-color: $gray-400;
  color: $dark-50;
  font-family:  Poppins, Helvetica, sans-serif !important;    
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  border-radius: 8px;
  border: 0;
  width: 100px;
  height: auto;
  outline: none;  
  margin-left: 10px;
  cursor: pointer;
}

//--> sender / footer

.rcw-sender {
  align-items: center;
  display: flex;
  background-color: $white;
  height: 45px;
  padding: 5px;
  border-radius: 0 0 10px 10px;

  &.expand {
    height: 55px;
  }
}

.rcw-powered-by {
    height: 35px;
    min-height: 35px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;    
}

.rcw-powered-by .terms {
    font-family:  Poppins, Helvetica, sans-serif !important;    
    position: relative;
    font-weight: 400;
    font-size: 13px;
    color: $dark;
    opacity: 0.2;
}
